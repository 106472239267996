import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import ContactModal from "../components/ContactFormModal/ContactModal";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import styles from "./PageLayout.module.scss";

const PageLayout = ({ children }) => {
  const [displayForm, setDisplayForm] = useState(false);
  const { locale } = useRouter();

  useEffect(() => {
    if (!displayForm) {
      const body = document.querySelector("body");
      body.style.overflow = "auto";
    }
  }, [displayForm]);

  const handleClick = () => {
    if (window.location.pathname === "/" + locale || window.location.pathname === "/") {
      const el = document.getElementById("contact-form");
      window.scroll({ top: el.offsetTop, behavior: "smooth" });
    } else {
      const body = document.querySelector("body");
      body.style.overflow = "hidden";
      setDisplayForm(true);
      return;
    }
  };

  return (
    <div className={styles["PageLayout"]}>
      <Header handleClickContact={handleClick} />
      {displayForm && <ContactModal setDisplayForm={setDisplayForm} />}

      {children}
      <Footer handleClickContact={handleClick} />
    </div>
  );
};

export default PageLayout;
