
export const sendMessage = async ({ name, email, content, origin }) => {
  try {
    await fetch('/api/contact', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name, email, content, origin })
    })
  } catch (error) {
    console.error(error);
  }
};
