import { useState, useEffect, useRef } from "react";
import Link from "next/link";
import Image from "next/image";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import style from "./Header.module.scss";
import enFlag from "../../img/en.svg";
import frFlag from "../../img/fr.svg";
import esFlag from "../../img/es.svg";
import logo from "../../img/logo-fd-code.svg";
import Wrapper from "../../layout/Wrapper";
import Search from "../Search/Search";
import useIsMobile from "../../hooks/useIsMobile";
import useClickOutside from "../../hooks/useClickOutside";
import Social from "../Social/Social";

const Header = ({ handleClickContact }) => {
  const [showMenu, setShowMenu] = useState(false);
  const menu = useRef(null);
  const isMobile = useIsMobile(992);
  const { t } = useTranslation();
  const { locale, locales, asPath } = useRouter();

  const handleClickOutside = () => isMobile && setShowMenu(!showMenu);

  useClickOutside(menu, handleClickOutside);

  useEffect(() => {
    isMobile ? setShowMenu(false) : setShowMenu(true);
  }, [isMobile]);

  return (
    <header className={style["header"]}>
      <Wrapper justify={"space-between"} className={style["header__wrapper"]}>
        <Link href={"/" + locale} locale={false} passHref>
          <a className={style["header__brand"]}>
            <Image width="100%" height="100%" objectFit="contain" src={logo.src} alt="Friend Code logo" />
          </a>
        </Link>
        {isMobile && (
          <div className={style["header__hamburger"]} onClick={() => setShowMenu(!showMenu)}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        )}
        {showMenu && (
          <div className={style["header__menu"]} ref={menu}>
            <ul>
              <li>
                <Link href={`/${locale}/about`}>{t("header.menu.about")}</Link>
              </li>
              <li>
                <Link href={`/${locale}/blog`}>{t("header.menu.blog")}</Link>
              </li>
              <li onClick={handleClickContact}>{t("header.menu.newGame")}</li>
              <li onClick={handleClickContact}>{t("header.menu.contact")}</li>
              <li>
                {locales.map((l, i) => {
                  return (
                    <Link key={i} href={`/${l}${asPath}`} passHref locale={false}>
                      <a className={style["header-menu-lang"]}>
                        <span className={l === locale ? style.selected : ""}>
                          {l === "en" && (<Image width={50} height={50} src={enFlag.src} alt="Switch to English" />)}
                          {l === "fr" && (<Image width={50} height={50} src={frFlag.src} alt="Switch to French" />)}
                          {l === "es" && (<Image width={50} height={50} src={esFlag.src} alt="Switch to Spanish" />)}
                        </span>
                      </a>
                    </Link>
                  );
                })}
              </li>
            </ul>
            <Search />
          </div>
        )}
        <Social
          className={style["header__social"]}
          flex={true}
          logoSize={30}
          color={"white"}
          text={t("social.header")}
        />
      </Wrapper>
    </header>
  );
};

export default Header;
